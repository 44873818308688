import { Container, FlexContainer, Heading, TextComponent, Size, Button } from '@cheddartv/storybook-news'

import style from '../styles/components/404'

export default () => {
  const handleNavigateHome = () => {
    window.location.href = '/'
  }

  return (
    <div className='wrapper'>
      <Container fullWidth padding={{ default: '0 1rem 2.5rem' }}>
        <FlexContainer
          justifyContent='center'
          direction='column'
          maxWidth='380px'
          margin='0px auto'
          alignItems='center'>
          <Container padding={{ default: '1rem 0 0 0', md: '3rem 0 0 0' }}>
            <img src='/404.svg' alt='' />
          </Container>
          <Heading tag='h2' fontWeight='medium'>
            Page not found
          </Heading>
          <Container padding={{ default: 0 }}>
            <TextComponent tag='p' size={Size.Small} fontWeight='medium'>
              We’re sorry, the page you requested could not be found.
            </TextComponent>
          </Container>
          <Container padding={{ default: 0 }}>
            <TextComponent tag='p' size={Size.Small} fontWeight='medium'>
              Please go back to homepage
            </TextComponent>
          </Container>
          <Container padding={{ default: '1rem 0 0 0' }}>
            <Button ariaLabel='Go back to the homepage' onClick={handleNavigateHome}>
              Back to Home
            </Button>
          </Container>
        </FlexContainer>
      </Container>
      <style jsx>{style}</style>
    </div>
  )
}
